import React, { useContext } from "react"
import i18n from "i18next"
import { LoadingContext } from "../context/LoadingContext"
import insta from "../../images/contactus/InstaNew.png"
import face from "../../images/contactus/FacebookNew.png"
import twitter from "../../images/contactus/TwitterNew.png"
import linkedin from "../../images/contactus/LinkedinNew.png"

const FollowUs = () => {
  const { windowWidth } = useContext(LoadingContext)
  return (
    <div className={windowWidth < 1025 ? "w-full desktop:pl-8 desktop:pr-4 flip-card bannerFollowUs mb-24":"w-full desktop:pl-8 desktop:pr-4 flip-card bannerFollowUs"}>
      <div className="flip-card-inner2">
        <div className="desktop:ml-16 mobile:ml-4">
          {windowWidth < 1025 ? (
            <>
              <p className="textAboutUs text-left mb-3">
                {i18n.t("pageContactUs.FollowUs1")}
              </p>
              <p className="titleOurServices">
                {i18n.t("pageContactUs.FollowUs2")}
                &nbsp;
                {i18n.t("pageContactUs.FollowUs3")}
              </p>
            </>
          ) : (
            <>
              <p className="textAboutUs text-left mb-3">
                {i18n.t("pageContactUs.FollowUs1")}
              </p>
              <p className="titleOurServices">
                {i18n.t("pageContactUs.FollowUs2")}
              </p>
              <p className="titleOurServices">
                {i18n.t("pageContactUs.FollowUs3")}
              </p>
            </>
          )}
        </div>
      </div>
      {windowWidth < 1025 ? (
        <>
          <div className="image-container-contact-us-mobile mt-5">
            <a
              href="https://www.instagram.com/exsisdigital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="ml-4 mr-5" src={insta} alt="Button" />
            </a>
            <a
              href="https://www.linkedin.com/company/exsis-digital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="mr-5" src={linkedin} alt="Button" />
            </a>
            <a
              href="https://www.facebook.com/Exsisdigitalangels/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="mr-5" src={face} alt="Button" />
            </a>
            <a
              href="https://twitter.com/exsisdigital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Button" />
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="image-container-contact-us flex justify-center">
            <a
              href="https://www.instagram.com/exsisdigital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="mr-5" src={insta} alt="Button" />
            </a>
            <a
              href="https://www.linkedin.com/company/exsis-digital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="mr-5" src={linkedin} alt="Button" />
            </a>
            <a
              href="https://www.facebook.com/Exsisdigitalangels/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="mr-5" src={face} alt="Button" />
            </a>
            <a
              href="https://twitter.com/exsisdigital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Button" />
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default FollowUs
