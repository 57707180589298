import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerPrivacyPolicy from "../components/privacypolicy/BannerPrivacyPolicy"
import PrivacyContent from "../components/privacypolicy/PrivacyContent"
import FollowUs from "../components/contactUs/FollowUs"
import { withNamespaces } from "react-i18next"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO
        title={"Privacy Policy | Exsis Digital Angels"}
        description={"Privacy Policy | Specialists in Tech Talent"}
      />
      <BannerPrivacyPolicy />
      <PrivacyContent />
      <FollowUs />
    </Layout>
  )
}

export default withNamespaces()(PrivacyPolicy)
